export default {
  main_manager: [
    {
      action: ["add", "list", "delete"],
      subject: ["Documents", "CreditScore"]
    },
    {
      action: ["add","edit"],
      subject: ["Recommendations"]
    },
    {
      action: ["change"],
      subject: ["PersonalData", "password", "avatar", "SSN"]
    },
    {
      action: ["delete"],
      subject: ["avatar"]
    },
    {
      action: ['edit'],
      subject: ['CreditScore', 'DisputeStatus']
    }
  ],
  admin: [
    {
      action: ["add", "list", "delete"],
      subject: ["Documents", "Recommendations", "CreditScore"]
    },
    {
      action: ["change"],
      subject: ["PersonalData", "password", "avatar", "SSN"]
    },
    {
      action: ["delete"],
      subject: ["avatar"]
    },
    {
      action: ['edit'],
      subject: ['CreditScore', 'DisputeStatus', "Recommendations"]
    }
  ],
  manager: [
    {
      action: ["add", "list"],
      subject: ["Documents"]
    },
    {
      action: ["add","edit"],
      subject: ["Recommendations"]
    },
    {
      action: ["change"],
      subject: ["PersonalData", "password", "avatar", "SSN"]
    },
    {
      action: ["delete"],
      subject: ["avatar", 'CreditScore']
    },
    {
      action: ['edit'],
      subject: ['CreditScore', 'DisputeStatus']
    }
  ],
  guest: [
    {
      action: ["add"],
      subject: ["Documents"]
    },
    {
      action: [],
      subject: ["Documents:self"]
    },
    {
      action: ["change"],
      subject: ["password", "avatar", "billing", "PersonalData"]
    }
  ]
};
